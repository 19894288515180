<script>
import NavBar from '@backend/part/NavBar.vue';
import SideBar from '@backend/part/SideBar.vue';
import RightSideBar from '@backend/part/RightSideBar.vue';
import GlobalVue from '@helper/Global.vue';
import Gen from '@helper/Gen';

export default {
  extends: GlobalVue,
  name:"BoLayout",
  components:{
    SideBar,
    NavBar,
    RightSideBar
  },
  computed:{
    yearcopy(){
      return Gen.yearCopy(this.web.yearcopy)
    }
  },
  mounted(){
    this.$set(this.$root, 'base', this)
  }
};
</script>
<template>
    <div id="main-wrapper">
      <NavBar></NavBar>
      <SideBar></SideBar>
      <div class="page-wrapper">
        <router-view/>
      </div>
      <RightSideBar></RightSideBar>
      <!-- Footer -->
      <footer class="footer text-center inspect"> {{yearcopy}} &copy; HiLo. All Rights Reserved. Powered by <a href="https://lingkar9.com" target="_blank" rel="noopener noreferrer">Lingkar9</a></footer>
      <FileManager></FileManager>
      <ImageFilter></ImageFilter>
    </div>
</template>